<template>
  <div>
    <title-nav :title="'결제현황 - 월별'" :nav="'결제관리 / 월별'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col>
              <b-input-group size="sm">
                <vuejs-datepicker
                  :value="this.start_date"
                  :format="DatePickerFormat"
                  :language="language"
                  v-on:selected="changeStartDate"
                  minimum-view="month"
                  name="datepicker"
                  id="input-id"
                  input-class="form-control custom-select-sm"
                />
                ~
                <vuejs-datepicker
                  :value="this.end_date"
                  :format="DatePickerFormat"
                  :language="language"
                  v-on:selected="changeEndDate"
                  minimum-view="month"
                  name="datepicker"
                  id="input-id2"
                  input-class="form-control custom-select-sm"
                />
              </b-input-group>
            </b-col>
          </b-row>

          <day-total :total="total" :totalProd="totalProd" />
          <day-stat
            :totalStat="totalStat"
            :convertVoucherType="convertVoucherType"
            :PAYMENT_CODE="PAYMENT_CODE"
          />
        </div>
        <div class="card-body p-0">
          <b-tabs content-class="mt-3">
            <b-tab title="개수" active>
              <b-table-simple
                striped
                hover
                bordered
                class="table-sm text-sm-center"
                :borderless="false"
                :fixed="true"
                :no-border-collapse="false"
                :head-variant="true"
                :busy="isBusy"
                show-empty
              >
                <b-thead head-variant="dark" class="text-center">
                  <b-tr>
                    <b-th> Date</b-th>
                    <template v-for="(item, idx) in header1">
                      <b-th :key="idx" v-if="item.split('_')[0] == 'c'">
                        <template v-if="item.split('_')[1] == 115">
                          {{ convertPaymentType(item.split('_')[2]) }}
                          <!-- {{ item.split('_') }} -->
                        </template>
                        <template v-if="item.split('_')[1] == 118">
                          {{ convertVoucherType(item.split('_')[2]) }}
                          <!-- {{ item.split('_') }} -->
                        </template>
                      </b-th>
                    </template>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(row, idx) in result_list" :key="idx">
                    <b-td>{{ row.yyyymm | YmFilter }}</b-td>
                    <b-td>{{ row.c_115_01 | commaFilter }}</b-td>
                    <b-td>{{ row.c_115_02 | commaFilter }}</b-td>
                    <b-td>{{ row.c_115_03 | commaFilter }}</b-td>
                    <b-td>{{ row.c_115_04 | commaFilter }}</b-td>
                    <b-td>{{ row.c_115_05 | commaFilter }}</b-td>
                    <b-td>{{ row.c_115_07 | commaFilter }}</b-td>
                    <b-td>{{ row.c_115_08 | commaFilter }}</b-td>
                    <b-td>{{ row.c_115_09 | commaFilter }}</b-td>
                    <b-td>{{ row.c_118_01 | commaFilter }}</b-td>
                    <b-td>{{ row.c_118_03 | commaFilter }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tab>
            <b-tab title="합계">
              <b-table-simple
                striped
                hover
                bordered
                class="table-sm text-sm-center"
                :borderless="false"
                :fixed="true"
                :no-border-collapse="false"
                :head-variant="true"
                :busy="isBusy"
                show-empty
              >
                <b-thead head-variant="dark" class="text-center">
                  <b-tr>
                    <b-th> Date</b-th>
                    <template v-for="(item, idx) in header2">
                      <b-th :key="idx" v-if="item.split('_')[0] == 's'">
                        <template v-if="item.split('_')[1] == 115">
                          {{ convertPaymentType(item.split('_')[2]) }}
                          <!-- {{ item.split('_') }} -->
                        </template>
                        <template v-if="item.split('_')[1] == 118">
                          {{ convertVoucherType(item.split('_')[2]) }}
                          <!-- {{ item.split('_') }} -->
                        </template>
                      </b-th>
                    </template>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(row, idx) in result_list" :key="idx">
                    <b-td>{{ row.yyyymm | YmFilter }}</b-td>
                    <b-td>{{ row.s_115_01 | commaFilter }}</b-td>
                    <b-td>{{ row.s_115_02 | commaFilter }}</b-td>
                    <b-td>{{ row.s_115_03 | commaFilter }}</b-td>
                    <b-td>{{ row.s_115_04 | commaFilter }}</b-td>
                    <b-td>{{ row.s_115_05 | commaFilter }}</b-td>
                    <b-td>{{ row.s_115_07 | commaFilter }}</b-td>
                    <b-td>{{ row.s_115_08 | commaFilter }}</b-td>
                    <b-td>{{ row.s_115_09 | commaFilter }}</b-td>
                    <b-td>{{ row.s_118_01 | commaFilter }}</b-td>
                    <b-td>{{ row.s_118_03 | commaFilter }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tab>
          </b-tabs>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="curPage"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import { getPaymentHistoryPerMonth } from '@/api/payment'

import { getPaymentType, getVoucherType } from '../../api/code'
import vuejsDatepicker from 'vuejs-datepicker'

import DayStat from './dayStat.vue'
import DayTotal from './dayTotal.vue'

var now = new Date()
var monthFirstDate = new Date(now.getUTCFullYear(), 0, 1)
var monthLastDate = new Date(now.getUTCFullYear(), now.getMonth() + 1, 0)

export default {
  name: 'payment_day',
  components: {
    'vuejs-datepicker': vuejsDatepicker,
    DayStat,
    DayTotal
  },
  data() {
    return {
      PAYMENT_CODE: '',
      VOUCHER_CODE: [],
      curPage: 1,
      rowPerPage: 20,
      records: 0,
      result_list: [],
      total_cnt: 0,
      total_price: 0,
      day_format: 'yyyy-MM',
      isBusy: false,
      start_date: this.$moment(monthFirstDate).format('YYYY/MM'),
      end_date: this.$moment(monthLastDate).format('YYYY/MM'),
      header1: [],
      header2: [],
      total: [],
      totalStat: [],
      totalProd: [],

      DatePickerFormat: 'yyyy-MM',
      language: {
        language: 'Korean',
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        monthsAbbr: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        days: ['일', '월', '화', '수', '목', '금', '토'],
        rtl: false,
        ymd: false,
        yearSuffix: ''
      }
    }
  },
  filters: {
    YmFilter: function(value) {
      if (!value) return ''
      return value.substring(0, 4) + '-' + value.substring(4)
    }
  },
  methods: {
    convertPaymentType(code) {
      if (code && this.PAYMENT_CODE) {
        const name = this.PAYMENT_CODE.find(value => value.cd_value === code).cd_name_kr
        return name
      } else return ''
    },
    async initPaymentType() {
      var resp = await getPaymentType()
      this.PAYMENT_CODE = resp
    },
    async initVoucherType() {
      var resp = await getVoucherType()
      this.VOUCHER_CODE = resp
    },
    convertVoucherType(code) {
      if (code && this.VOUCHER_CODE) {
        const name = this.VOUCHER_CODE.find(value => value.cd_value === code)?.cd_name_kr
        if (!name) {
          return null
        }
        return name
      } else return 'code is empty'
    },
    changeStartDate(val) {
      this.start_date = this.$moment(val).format('YYYY/MM')
      this.curPage = 1
      this.init()
    },
    changeEndDate(val) {
      this.end_date = this.$moment(val).format('YYYY/MM')
      this.curPage = 1
      this.init()
    },
    clear() {
      location.reload()
    },
    setPage(page) {
      this.curPage = page
      this.result_list = []
      this.init()
    },
    async init() {
      try {
        this.total_price = 0
        this.total_cnt = 0
        this.isBusy = true
        var startDate = this.$moment(this.start_date).format('YYYYMM') + '01'
        var endDate = this.$moment(this.end_date).format('YYYYMM') + '31'
        const resp = await getPaymentHistoryPerMonth(
          this.curPage,
          this.rowPerPage,
          startDate,
          endDate
        )
        if (resp.code === '0000') {
          this.result_list = resp.data.list
          this.total = resp.data.total
          this.totalProd = resp.data.totalProd
          this.totalStat = resp.data.totalStat
          this.records = resp.data.totCnt

          if (resp.data.list.length > 0) {
            this.header1 = Object.keys(resp.data.list[0]).filter(
              item => item.indexOf('_') > 0 && item.split('_')[0] == 'c'
            )
            this.header2 = Object.keys(resp.data.list[0]).filter(
              item => item.indexOf('_') > 0 && item.split('_')[0] == 's'
            )
            this.header1 = this.header1.sort((a, b) => {
              return a.split('_')[1] - b.split('_')[1] || a.split('_')[2] - b.split('_')[2]
            })
            this.header2 = this.header2.sort((a, b) => {
              return a.split('_')[1] - b.split('_')[1]
            })
          }
        } else {
          alert(`error:${resp.code}`)
        }
      } catch (error) {
        alert(`error:${error}`)
      }
      this.isBusy = false
    }
  },
  mounted() {
    this.initPaymentType()
    this.initVoucherType()
    this.init()
  }
}
</script>

<style scoped>
.myTable {
  width: 100%;
  height: 100%;
  border: 1px solid grey;
}
.myTable th {
  width: 15%;
}
.myTable td {
  width: 25%;
}
th,
td {
  border: 1px solid slategray;
  white-space: nowrap;
}
.border {
  border: 1px solid rgb(17, 17, 17) !important;
}
</style>
